<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateUserModal()"
        ><PlusOutlined />新增</a-button
      >
    </div>
    <div class="table-search">
      <a-form
        :model="param"
        layout="inline"
        autocomplete="off"
        @submit="handleRefresh"
      >
        <a-form-item label="账号">
          <a-input v-model:value="param.username" placeholder="账号" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table
      rowKey="menu_id"
      :columns="columns"
      :data-source="data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateUserModal(record)">编辑</a>
            <a-divider type="vertical" v-if="record.is_root === 0" />
            <a @click="showDeleteConfirm(record)" v-if="record.is_root === 0"
              >删除</a
            >
          </span>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal
    v-model:visible="visible"
    :title="title"
    @ok="handleSubmit"
    @cancel="onCancel"
    :width="720"
  >
    <a-form ref="formRef" v-bind="layout" :model="formState">
      <a-form-item has-feedback label="姓名" name="real_name">
        <a-input
          v-model:value="formState.real_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="账号" name="user_name">
        <a-input
          v-model:value="formState.user_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="所属角色" name="role_id">
        <a-select
          ref="select"
          v-model:value="formState.role_id"
          style="width: 120px"
          :options="RefRoleList"
          @change="handleChange"
        ></a-select>
      </a-form-item>
      <a-form-item has-feedback label="登录密码" name="password">
        <a-input
          v-model:value="formState.password"
          type="password"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="确认密码" name="check_password">
        <a-input
          v-model:value="formState.check_password"
          type="password"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="状态" name="status">
        <a-switch
          v-model:checked="checked"
          checked-children="正常"
          un-checked-children="禁用"
        />
      </a-form-item>
      <a-form-item has-feedback label="排序" name="sort" extra="数字越小越靠前">
        <a-input
          class="mini-input"
          type="number"
          v-model:value="formState.sort"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import {
  createVNode,
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { Modal, message } from "ant-design-vue";
import * as user from "@/addons/manage/api";
const columns = [
  {
    title: "管理员ID",
    dataIndex: "admin_user_id",
    width: "10%",
  },
  {
    title: "账号",
    dataIndex: "user_name",
    width: "12%",
  },
  {
    title: "姓名",
    dataIndex: "real_name",
    width: "10%",
  },
  {
    title: "排序",
    dataIndex: "sort",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
export default defineComponent({
  components: {
    PlusOutlined,
  },
  setup() {
    const param = reactive({
      username: "",
    });
    const title=ref();
    const formRef = ref();
    const checked = ref(true);
    const formState = reactive({
      real_name: "",
      user_name: "",
      role_id: 1,
      password: "",
      check_password: "",
      sort: "100",
      status: 1,
      admin_user_id: 0,
    });

    const visible = ref(false);

    const data = ref([]);
    const handleSubmit = () => {
      formRef.value.validate().then(() => {
        if (formState.admin_user_id) {
          user.user_update(formState).then((res) => {
            if(res.status==200){
              message.success(res.message);
              formRef.value.resetFields();
              visible.value = false;
              // 通知父组件刷新列表
              handleRefresh();
            }else{
              message.error(res.message)
            }

          });
        } else {
          user.user_create(formState).then((res) => {
            if(res.status==200){
              message.success(res.message);
              formRef.value.resetFields();
              visible.value = false;
              // 通知父组件刷新列表
              handleRefresh();
            }else{
              message.error(res.message)
            }
          });
        }
      });
    };
    const onCancel = () => {};
    // 添加用户

    const showCreateUserModal = () => {
      formState.admin_user_id = 0;
      formState.real_name = '';
      formState.user_name = '';
      formState.role_id = 1;
      formState.sort = 100;
      formState.status = 1;
      visible.value = true;
      title.value='添加用户';
    };

    // 编辑用户

    const showUpdateUserModal = (item) => {
      formState.admin_user_id = item.admin_user_id;
      formState.real_name = item.real_name;
      formState.user_name = item.user_name;
      formState.role_id = item.role_id;
      formState.sort = item.sort;
      formState.status = item.status;
      visible.value = true;
      title.value='编辑用户';
    };

    // 刷新用户列表数据
    const handleRefresh = () => {
      user.user_list(param).then((res) => {
        data.value = res.data.list;
      });
    };

    // 角色列表
    const RefRoleList = ref();

    // 刷新角色列表数据
    const getRoleList = () => {
      user.roles().then((res) => {
        RefRoleList.value = res.data;
      });
    };

    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.real_name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除用户后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          user.user_del({ admin_user_id: record.admin_user_id }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };
    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };
    watch(checked, () => {
      formState.status = checked.value ? 1 : 0;
    });
    onMounted(() => {
      handleRefresh();
      getRoleList();
    });

    return {
      handleChange,
      formState,
      columns,
      data,
      showCreateUserModal,
      showUpdateUserModal,
      showDeleteConfirm,
      handleRefresh,
      RefRoleList,
      handleSubmit,
      onCancel,
      param,
      visible,
      checked,
      layout,
      formRef,
      title
    };
  },
});
</script>